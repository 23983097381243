import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

//Components
import HeaderEventSearch from "../../components/HeaderEventSearch";
import TableInputs from "../EventSearch/Table/TableInputs";
import TableResults from "../EventSearch/Table/TableResults";
import Button from "../../components/Button";

import { searchEvents } from "../../providers/EventSearch/searchEvents";

import "./style.css";

// require("dotenv").config();

export default function EventSearch() {
  const INITIAL_DATA = {
    value: 0,
    label: "Select Hotel...",
  };

  const INITIAL_DATA_PERIOD = {
    value: 0,
    label: "Select the Period...",
  };

  const INITIAL_DATA_DISTANCE = {
    value: 0,
    label: "Select the Distance...",
  };

  const [selectHotel, setSelectHotel] = useState(INITIAL_DATA);
  const [selectedDistance, setSelectedDistance] = useState(INITIAL_DATA_DISTANCE);
  const [isLoading, setIsLoading] = useState(false);
  const [resultPlaceDetails, setResultPlaceDetails] = useState([]);
  const [resultCustomSearch, setResultCustomSearch] = useState([]);
  const [PDFLinksWithError, setPDFLinksWithError] = useState([]);
  const [PDFFilesWithError, setPDFFilesWithError] = useState([]);
  const [resultMainHotelOpportunities, setResultMainHotelOpportunities] = useState([]);
  const [resultMainHotelDetails, setResultMainHotelDetails] = useState(null);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [captchaIsVisible, setCaptchaIsVisible] = useState(false);


  //Call nearbySearch endpoint to get the hotels infos from Rates API (place_id)
  const eventSearch = async () => {
    if (selectedDistance.value === 0) {
      toast.error("Please select a valid Distance");
    } else {
      const params = {
        location: selectHotel.location,
        mainHotel: selectHotel,
        radius: selectedDistance.value,
      };

      setIsLoading(true);
      const result = await searchEvents(params);
      setResultPlaceDetails(result.places);
      console.log("all hotels nearby: ", result.unfilteredNearbyHotels);
      console.log("nearby search filtered results: ", result.places);
      setResultMainHotelOpportunities(result.flattenedResultsMainHotel);
      setResultCustomSearch(result.resultCustomSearch);
      setResultMainHotelDetails(result.mainHotelDetails);
      setPDFLinksWithError(result.PDFLinksWithError);
      setPDFFilesWithError(result.PDFFilesWithError);
      setIsLoading(false);
      // setCaptchaIsVisible(true);
      cleanInputs();
    }
  }

  //Button to send event infos to the card
  const runButton = async () => {
    // if (captchaValue) {
    //   setIsLoading(true);
    //   const res = await verifyCaptcha(captchaValue);
    //   setCaptchaIsVisible(false);
    // if (res) {
    eventSearch();
    // } else {
    //   toast.error("Invalid CAPTCHA");
    //   setIsLoading(false);
    //   setCaptchaIsVisible(true);
    //   cleanInputs();
    // }
    // };
  }

  const cleanInputs = () => {
    setSelectHotel(INITIAL_DATA);
    setSelectedDistance(INITIAL_DATA_DISTANCE);
  };

  const verifyCaptcha = async (id) => {
    let requestURL = (
      window?.location.href.includes("localhost")
        ? "http://localhost:3333"
        : process.env.REACT_APP_BASE_URL // TO DO: Add captcha to bezla-functions
      // : process.env.REACT_APP_BASE_URL
    ) + '/features/validateCaptcha';

    // Comentar o try catch abaixo e adicionar o console.log console.log("ID >>>", id);
    // para gerar no console o ID que será usado no postman no endpoint getCaptcha para gerar o token do
    // header x-access-token que irá executar as chamadas

    try {
      const res = await axios.get(`${requestURL}/${id}`)

      const data = res.data.data;

      return data.success ? true : data === "OK" ? true : false;
    } catch (error) {
      toast.error(error.message);
      return false;
    }
  };

  return (
    <>
      <ToastContainer />
      <HeaderEventSearch />
      <MainSection>
        <Watermark />
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <HeaderTitle className="mt-5">
                <h1>Revenue Opportunities Finder</h1>
                <span>
                  Choose a Hotel to search revenue opportunities around them.
                </span>
              </HeaderTitle>

              <InputsContainer>
                <TableInputs
                  selectedDistance={selectedDistance}
                  setSelectedDistance={setSelectedDistance}
                  selectHotel={selectHotel}
                  setSelectHotel={setSelectHotel}
                  resultCustomSearch={resultCustomSearch}
                  setResultCustomSearch={setResultCustomSearch}
                />
              </InputsContainer>
              <CaptchaContainer>
                {captchaIsVisible && (
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_SITEKEY}
                    onChange={(item) => setCaptchaValue(item)}
                    onErrored={() => setCaptchaValue(null)}
                  />
                )}
              </CaptchaContainer>
              <ButtonsContainer>
                <Button
                  label="RUN"
                  onClick={() => {
                    runButton();
                  }}
                />
              </ButtonsContainer>
              <TableResults
                resultPlaceDetails={resultPlaceDetails}
                resultCustomSearch={resultCustomSearch}
                resultMainHotelOpportunities={resultMainHotelOpportunities}
                resultMainHotelDetails={resultMainHotelDetails}
                PDFLinksWithError={PDFLinksWithError}
                PDFFilesWithError={PDFFilesWithError}
              />
            </div>
          </div>
          {isLoading && (
            <LoadingModal>
              <div>
                <AiOutlineLoading3Quarters />
                <strong>Loading...</strong>
                <span id="modal-loading-percent"></span>
                {/* <h3 id="loadingMessage">{defaultLoadingMessages.message1}</h3> */}
              </div>
            </LoadingModal>
          )}
        </div>
        {resultPlaceDetails.length > 0 &&
          <button onClick={() => { const element = document.getElementById("nearbyhotels"); if (element) { element.scrollIntoView({ behavior: 'smooth', block: "center" }); } }} type="button" data-mdb-ripple="true" data-mdb-ripple-color="light" className="btn-back-to-top" style={{ position: "fixed", bottom: "1rem", right: "1rem", display: "inline-block", padding: "0.75rem", backgroundColor: "rgb(87, 87, 87)", color: "white", fontWeight: "500", fontSize: "0.75rem", lineHeight: "1.25", textTransform: "uppercase", width: "50px", height: "50px", borderRadius: "100%", borderStyle: "solid", borderColor: "rgb(87, 87, 87)", boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)" }}>
            <svg aria-hidden="true" focusable="false" data-prefix="fas" class="w-4 h-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M34.9 289.5l-22.2-22.2c-9.4-9.4-9.4-24.6 0-33.9L207 39c9.4-9.4 24.6-9.4 33.9 0l194.3 194.3c9.4 9.4 9.4 24.6 0 33.9L413 289.4c-9.5 9.5-25 9.3-34.3-.4L264 168.6V456c0 13.3-10.7 24-24 24h-32c-13.3 0-24-10.7-24-24V168.6L69.2 289.1c-9.3 9.8-24.8 10-34.3.4z"></path></svg>
          </button>
        }
      </MainSection>
    </>
  );
}

const Watermark = styled.div`
  position: fixed;
  width: 90%;
  height: 90%;
  margin: auto;
  z-index: -1;
  background-size: 30%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("/watermark.jpeg");
  opacity: 0.15;
`;

const MainSection = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const HeaderTitle = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem;
  margin-top: 12%;
  margin-left: 2.7%;

  & > h1 {
    color: #ab8585;
    font-size: 1.5rem;
    line-height: 2rem;
    font-family: "Playfair Display", serif;
  }

  & > span {
    font-family: "Open Sans", serif;
    line-height: 1.375rem;
    margin-top: 0.5rem;
    color: #575757;
  }

  @media (max-width: 1024px) {
    margin-top: 15%;
  }

  @media (max-width: 768px) {
    margin-top: 20%;
  }

  @media (max-width: 500px) {
    margin-top: 30%;
  }

  @media (max-width: 375px) {
    margin-top: 35%;
  }
`;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 5%;
  margin-left: 2.7%;
`;

const CaptchaContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 1rem;
  padding-left: 1.5rem;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-bottom: 5rem;

  & > button {
    margin-left: 1.5rem;
  }
`;

const LoadingModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.4);
  font-family: "Open Sans", sans-serif;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #333333;
    max-width: 40%;

    background: white;
    padding: 2rem 2rem 1rem 2rem;

    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;

    & > svg {
      animation: spin 2s linear infinite;

      @keyframes spin {
        0% {
          transform: rotate(0deg) scale(3);
        }
        100% {
          transform: rotate(360deg) scale(3);
        }
      }
    }

    & > strong {
      padding-top: 2rem;
    }

    & > h3 {
      padding-top: 1.5rem;
      padding-bottom: 0.5rem;
      text-align: center;
    }
  }
`;
