import React from "react";
import styled from "styled-components";
import Select from "react-select";

export default function Input({
  defaultValue,
  onChange,
  options,
  isSearchable,
  placeholder,
}) {
  return (
    <InputComponent>
      <Select
        cacheOptions
        value={defaultValue}
        onChange={onChange}
        options={options}
        isSearchable={isSearchable}
        placeholder={placeholder}
      />
    </InputComponent>
  );
}

const InputComponent = styled.div`
  width: 25vw;
  color: #575757;
  border-radius: 5px;
  font-weight: 400;

  @media (min-width: 1441px) {
    width: 15vw;
  }

  @media (max-width: 1024px) {
    width: 45vw;
  }
`;
