import React, { useState, useCallback } from "react";
import styled from "styled-components";
import axios from "axios";
import { useCombobox } from "downshift";
import { debounce } from "lodash";
// require("dotenv").config();

export default function DropdownInput({ selectHotel, setSelectHotel, resultCustomSearch, setResultCustomSearch }) {
  const [inputValue, setInputValue] = useState("");
  const [hotelsOptions, setHotelsOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const BASE_URL = (
    window?.location.href.includes("localhost")
      ? "http://localhost:8000"
      // ? "https://homolog-eventssearch-backend.axion.company"
      : process.env.REACT_APP_BASE_URL
  ) + '/features/google/searchHotels';

  async function hotelApi(query) {
    if (query.length > 3) {
      const { data } = await axios.post(`${BASE_URL}`, { query });
      const dataHotel = data?.data
      console.log(dataHotel);
      const hotelOptions = dataHotel.map((hotel) => {
        return {
          label: `${hotel.name} | ${hotel.formatted_address}`,
          value: hotel.place_id,
          location: hotel.geometry.location,
        };
      })
      return hotelOptions;
    }
  }

  const debounceGetHotels = useCallback(
    debounce((query) => {
      
      hotelApi(query).then((options) => {
        setHotelsOptions(options);
        setIsLoading(false);
      });
    }, 1000),
    []
  );

  const handleInputChange = (newInputValue) => {
    setInputValue(newInputValue);
    if (newInputValue.length > 3) {
      setIsLoading(true);
      debounceGetHotels(newInputValue);
    } else {
      setHotelsOptions([]);
      setIsLoading(false);
    }
  };

  // combobox configs
  const {
    isOpen,
    getMenuProps,
    getInputProps,
    getItemProps,
    highlightedIndex,
    selectedItem,
  } = useCombobox({
    items: hotelsOptions,
    inputValue,
    onInputValueChange: ({ inputValue }) => {
      handleInputChange(inputValue);
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem) {
        setSelectHotel(selectedItem);
        setInputValue(selectedItem.label);
      }
    },
    itemToString: (item) => (item ? item.label : ""),
  });

  const clearInput = () => {
    setInputValue("");
    setHotelsOptions([]);
    setSelectHotel(null);
  };

  return (
    <DropDownComponent>
      <div style={{ position: "relative" }}>
        <input
          {...getInputProps({
            placeholder: "Select a Hotel...",
          })}
          style={{
            width: "100%",
            height: "3rem",
            padding: "8px",
            paddingRight: "40px",
            fontSize: "1.25rem",
            fontamily: "Open Sans, sans-serif",
            fontWeight: "400",
            lineHeight: "2rem",
            backgroundColor: "#FFF",
            color: "#575757",
            border: "1px solid #cccccc",
            outlineColor: "#2684ff",
            borderRadius: "4px",
          }}
        />
        {inputValue && (
          <ClearButton onClick={clearInput}>&times;</ClearButton>
        )}
        <ul
          {...getMenuProps()} // dropdown css
          style={{
            border: isOpen ? "1px solid #ccc" : "none",
            padding: 0,
            margin: 0,
            listStyle: "none",
            maxHeight: "20rem",
            overflowY: "auto",
            position: "absolute",
            width: "100%",
            zIndex: 1,
            backgroundColor: "white",
            top: "115%",
            left: 0,
            borderRadius: "4px"
          }}
        >
        {isOpen &&
          <>
            {isLoading ? (
              <li style={{ padding: "8px", color: "#999", textAlign: "center", fontSize: "1.25rem", fontamily: "Open Sans, sans-serif", fontWeight: "400" }}>Loading...</li>
            ) : hotelsOptions.length === 0 ? (
              <li style={{ padding: "8px", color: "#999", textAlign: "center", fontSize: "1.25rem", fontamily: "Open Sans, sans-serif", fontWeight: "400" }}>No options</li>
            ) : (
            hotelsOptions.map((hotel, index) => (
              <li
                key={hotel.value}
                {...getItemProps({ // dropdown items css
                  item: hotel,
                  index,
                  style: {
                    backgroundColor:
                      highlightedIndex === index ? "#c7e8ff" : "white",
                    color: "#575757",
                    fontSize: "1.25rem",
                    fontamily: "Open Sans, sans-serif",
                    fontWeight: "400",
                    lineHeight: "2rem",
                    padding: "0.5rem",
                    cursor: "pointer",
                  },
                })}
              >
                {hotel.label}
              </li>
              ))
            )}
          </>
        }
        </ul>
      </div>
    </DropDownComponent>
  );
}

const DropDownComponent = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.25rem;
  font-weight: 400;
  margin-bottom: 2.5rem;
  position: relative;

  & > span {
    margin-bottom: 0.5rem;
  }

  @media (min-width: 1024px) {
    width: 150%;
  }
`;

const ClearButton = styled.button`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #999;
  font-size: 1.25rem;
  cursor: pointer;
  &:hover {
    color: #000;
  }
`;