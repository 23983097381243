import React from "react";
import styled from "styled-components";

//Components
import Dropdown from "../../../components/Dropdown";
import InputPeriod from "../../../components/InputComponent";
import InputDistance from "../../../components/InputComponent";

export default function TableInputs({
  selectedDistance,
  setSelectedDistance,
  selectHotel,
  setSelectHotel,
  resultCustomSearch,
  setResultCustomSearch
}) {
  
  const Distance = [
    {
      label: "200m",
      value: "200",
    },
    {
      label: "1 mile",
      value: "1609",
    },
    {
      label: "3 miles",
      value: "4828",
    },
    {
      label: "5 miles",
      value: "8047",
    },
    {
      label: "10 miles",
      value: "16093",
    },
  ];

  return (
    <>
      <Container>
        <InputHotelSearch>
          <span>Search Hotel</span>
          <Dropdown
            selectHotel={selectHotel}
            setSelectHotel={setSelectHotel}
            resultCustomSearch={resultCustomSearch}
            setResultCustomSearch={setResultCustomSearch}
          />
        </InputHotelSearch>
        <InputContainer>
          <InputPeriodDistance>
            <FieldContainer>
              <span>Select the maximum distance for search</span>
              <InputDistance
                cacheOptions
                defaultValue={selectedDistance}
                onChange={setSelectedDistance}
                options={Distance}
                isSearchable={false}
                placeholder={"Select the distance..."}
              />
            </FieldContainer>
          </InputPeriodDistance>
        </InputContainer>
      </Container>
    </>
  );
}

const InputHotelSearch = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 2.5rem;
  width: 44%;
  font-family: "Open Sans", sans-serif;
  
  & input {
    height: 40px;
  }

  @media (max-width: 1024px) {
    width: 50%;
  }
`;

const InputContainer = styled.div`
  font: Open Sans, serif;
  color: #575757;
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
`;

const InputPeriodDistance = styled.div`
  display: flex;
  width: 68%;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 2.5rem;
  font-family: "Open Sans", sans-serif;

  & input {
    height: 50px;
  }
 
  @media (max-width: 2560px) {
    width: 80%;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: left;
  
    & input {
      width: inherit;
    }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FieldContainer = styled.div`
  width: 80%;

  @media (max-width: 1024px) {
    margin-bottom: 3vh;
  
    & > span {
      width: 100%;
    }
`;
