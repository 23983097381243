const parseField = (field, type) => {
  field = field.split(",").join("");
  switch (type) {
    case "integer":
      return Math.round(Number(field));
    case "percentage":
      return (Number(field).toFixed(2));
    case "money":
      const formated = new Intl.NumberFormat("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(field);
      if (!formated) throw new Error("Invalid field");
      return formated;
    default:
      throw new Error("Type not especified");
  }
};


const selectDatesFromIterval = (day, start, end) => {
  const listDays = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
  const indexDay = listDays.indexOf(day);
  const startDate = new Date(start)
  const startMonth = startDate.getUTCMonth();
  const startYear = startDate.getFullYear();
  const startDay = startDate.getUTCDate();
  const endDate = new Date(end)
  const numberOfDays = Math.ceil(
    (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24)
  );

  let selected = [];

  for (let i = 0; i <= numberOfDays; i++) {
    let t = new Date(startYear, startMonth, startDay + i);
    if (t.getDay() === indexDay) {
      let month = t.getUTCMonth() < 10 ? "0" + (t.getUTCMonth() + 1) : t.getUTCMonth() + 1;
      let date = t.getDate() < 10 ? ("0" + t.getDate()) : t.getDate();

      selected.push(`${month}/${date}/${t.getFullYear()}`);
    }
  }

  return selected;
}

export { parseField, selectDatesFromIterval };
