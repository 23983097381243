import React, { useState } from "react";
import styled from "styled-components";
import Header from "../../../components/Header";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import Footer from "../../../components/Footer";

export default function Home() {
  const [captchaValue, setCaptchaValue] = useState(null);

  let navigate = useNavigate();

  const onSubmitButton = async () => {
    if (captchaValue) {
      const res = await verifyCaptcha(captchaValue);
      if (res) {
        navigate(`/eventsearch/eventsearch`);
      } else {
        toast.warn("Please revalidate the reCAPTCHA");
      }
    } else {
      toast.warn("Please fill the reCAPTCHA.");
    }
  };

  const verifyCaptcha = async (id) => {
    let requestURL = (
      window?.location.href.includes("localhost")
        ? "http://localhost:3333"
        : process.env.REACT_APP_BASE_URL // TO DO: Add captcha to bezla-functions
      // : process.env.REACT_APP_BASE_URL
    ) + '/connectors/googleCaptcha/index';

    // Comentar o try catch abaixo e adicionar o console.log console.log("ID >>>", id);
    // para gerar no console o ID que será usado no postman no endpoint getCaptcha para gerar o token do
    // header x-access-token que irá executar as chamadas

    try {
      const res = await axios.post(requestURL, {
        token: id,
      })

      const data = res.data;

      return data.success ? true : data === "OK" ? true : false;
    } catch (error) {
      toast.error(error.message);
      return false;
    }
  };

  return (
    <Container>
      <MainSection>
        <ToastContainer />
        <Header />
        <TitleSection>
          <h1>Events Search</h1>
          <span>
            Explore past, current, and upcoming events around your hotel with <br />
            Bezla's event search feature.
          </span>
        </TitleSection>
        <ButtonSection>
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_SITEKEY}
            onChange={(item) => setCaptchaValue(item)}
            onErrored={() => setCaptchaValue(null)}
          />
          <button onClick={() => onSubmitButton()} type="button">
            START EVENTS SEARCH
          </button>
        </ButtonSection>
      </MainSection>
      <FooterSection>
        <Footer />
      </FooterSection>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const MainSection = styled.div`
  padding: 0 2rem;
  background: #343a40;
`;

const FooterSection = styled.div`
  padding: 0 2rem;
  background: rgba(22,22,22,1);
`;

const TitleSection = styled.section`
  color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 6rem;
  & > h1 {
    text-shadow: 2px 2px 2px rgb(0 0 0 / 10%);
    font-weight: 700;
    line-height: 1.3;
    font-size: 4.5rem;
    font-family: "Open Sans", sans-serif;
  }

  & > span {
    text-shadow: 2px 2px 2px rgb(0 0 0 / 10%);
    text-align: center;
    font-size: 1.25rem;
    margin-top: 0;
    margin-bottom: 1rem;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
`;

const ButtonSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 10rem;

  & > button {
    background-color: white;
    padding: 0.8rem 3rem;
    letter-spacing: 0.1rem;
    font-weight: 700;
    font-size: 1.2rem;
    border-radius: 4px;
    margin-top: 2rem;
    border-style: solid;
    border-color: transparent;
    cursor: pointer;
    font-family: "Open Sans", serif;
  }

  & > button:hover {
    background-color: rgb(230, 230, 230);;
  }
`;
