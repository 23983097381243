import axios from "../../utils/requests";

const BASE_URL = window.location.href.indexOf('localhost') > -1
  ? 'http://localhost:3333'
  : 'https://8pwaczlquf.execute-api.us-east-1.amazonaws.com'

const getHotels = async (query = "") => {
  try {
    const { data } = await axios.post(`${BASE_URL}/features/hotelSearch/search`,{ query, provider: "tripAdvisor" });
    return data.data;
  } catch (err) {
    console.log(err);
  }
};

export default getHotels;
