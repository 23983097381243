import axios from "../../utils/requests";

// Define Base Url
const BASE_URL = 
window.location.href.indexOf("localhost") > -1
? "http://localhost:3333"
: "https://2ve3dhqx09.execute-api.us-east-1.amazonaws.com";

const addDays = (base, days) => {
  const date = new Date(base).getTime() + 1000 * 60 * 60 * 24 * days;
  return new Date(date).toISOString().split("T")[0];
};

// 1. make dates array from start to end date;
const buildParams = (hotels, startDate, endDate) => {
  const params = []
  hotels.forEach((hotel) => {
    params.push({
      hotelName: hotel.hotelName,
      hotelId: hotel.hotelId,
      locId: 0,
      checkIn: startDate,
      checkOut: endDate,
    });
  });
  return params;
};

// 2. send request to API
const getHotelRates = async (params) => {
  try {
    const url = `${BASE_URL}/features/hotelRates/rates`;
    const {
      data: { jobId },
    } = await axios.post(url, {data: params, provider: "kayak"});
    const data = await getJobResult(jobId);
    if (data.includes("Error")) {
      await timeout(10000);
      return await getHotelRates(params);
    } else {
      return data;
    }
  } catch (err) {
    console.log(err);
    await timeout(10000);
    return await getHotelRates(params);
  }
};

// 2.1 recursive request for updating status of job
const getJobResult = async (jobId, errors = 0) => {
  try {
    const url = `${BASE_URL}/features/hotelRates/getJobId`;
    const { data } = await axios.post(url, {jobId});
    if (data?.data.data === "loading...") {
      await timeout(5000);
      return await getJobResult(jobId);
    } else {
      return data.data;
    }
  } catch (err) {
    return `Error: ${err}`;
  }
};

// 3. transform the payload into the used format used in front-end
const transformData = (data) => {
  data.forEach((hotelObj) => {
    hotelObj.dates.forEach((datesObj) => {
      const isError = typeof datesObj.rates === "string" ? true : false;
      const minRate =
        !isError && datesObj.rates && datesObj.rates.length
          ? Math.min(...datesObj?.rates?.map((itemRate) => itemRate.rate))
          : 0;
      const bestPriceProviders =
        datesObj.rates && !isError
          ? datesObj.rates
              .filter((item) => String(item.rate) === String(minRate))
              .map((item) => item.provider)
          : [];
      datesObj.date = datesObj.checkIn;
      datesObj.rate = minRate;
      datesObj.code = bestPriceProviders;
      datesObj.error = isError;
      datesObj.error_message = isError ? datesObj.rates : null;
    });
  });

  return data;
};

// 4. return the data
const getRates = async (hotels, checkIn, checkOut) => {
  const params = buildParams(hotels, checkIn, checkOut);
  console.log(params);
  const data = await getHotelRates(params);
  const parsedData = transformData(data);
  console.log(parsedData);
  return parsedData;
};

function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default getRates;
