import axios from "../../utils/requests";
// require("dotenv").config();

const BASE_URL =
  window.location.href.indexOf("localhost") > -1
    ? "http://localhost:3333"
    : "https://2ve3dhqx09.execute-api.us-east-1.amazonaws.com";

const getHotels = async (body) => {
  try {
    const { data } = await axios.post(`${BASE_URL}/features/hotelSearch/search`, {...body, provider: "kayak"});
    return data.data;
  } catch (err) {
    console.log(err);
  }
};

export default getHotels;
