/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState, useRef } from "react";
import styled from "styled-components";
import { AiFillCaretDown } from "react-icons/ai";

//BootStrap imports
import ContainerBoot from "react-bootstrap/Container";
import RowBoot from "react-bootstrap/Row";
import ColBoot from "react-bootstrap/Col";
import CardsBoot from "react-bootstrap/Card";

export default function TableResults({
  resultCustomSearch,
  resultPlaceDetails,
  resultMainHotelOpportunities,
  resultMainHotelDetails,
  PDFLinksWithError,
  PDFFilesWithError,
}) {
  const graphRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const scrollToResults = () => {
    graphRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  };

  const handleNearbyHotelClick = (e, hotelName) => {
    const element = document.getElementById(hotelName);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: "center" });
    }
  }

  return (
    <>
      {resultCustomSearch?.length !== 0 ? scrollToResults() : null}
      <ContainerBoot ref={graphRef}>
        {resultMainHotelDetails ? (
          <HeaderTitle>
            <span id="nearbyhotels">Main Hotel Information</span>
          </HeaderTitle>
        ) : (
          <span></span>
        )}
      </ContainerBoot>
      {resultMainHotelDetails ? (
        <ContainerBoot>
          <NearbyHotelsList>
            <span>
              {resultMainHotelDetails.name} | {resultMainHotelDetails.formatted_address} | {resultMainHotelDetails.formatted_phone_number}
            </span>
          </NearbyHotelsList>
        </ContainerBoot>
      ) : (
        <span></span>
      )}
      <ContainerBoot>
        {resultPlaceDetails?.length !== 0 ? (
          <HeaderTitle>
            <span id="nearbyhotels">Nearby Hotels - Total results: {resultPlaceDetails.length}</span>
          </HeaderTitle>
        ) : (
          <span></span>
        )}
      </ContainerBoot>
      {resultPlaceDetails?.length !== 0 ? (
        <ContainerBoot>
          <NearbyHotelsList>
            {resultPlaceDetails.map((item) => {
              return (
                <NearbyHotelsSpan onClick={(e) => handleNearbyHotelClick(e, item.name)}>
                  {item.name} | {item.formatted_address} | {item.formatted_phone_number} | Distance:{(Number((item?.distance || 0)) / 1.609).toFixed(2)} miles
                </NearbyHotelsSpan>
              );
            })}
          </NearbyHotelsList>
        </ContainerBoot>
      ) : (
        <span></span>
      )}
      <ContainerBoot>
        {resultCustomSearch?.length !== 0 ? (
          <>
            <HeaderTitle>
              <span>Revenue Opportunities Found: {resultCustomSearch.length} </span>
            </HeaderTitle>
          </>
        ) : (
          <span></span>
        )}
      </ContainerBoot>
      <>
        <ResultsCardContainer >
          <ContainerBoot >
            <RowBoot className="g-4">
              {
                resultCustomSearch && resultCustomSearch.length !== 0
                && Object.entries(resultCustomSearch.formattedArray).map(([key, element]) => (
                  <>
                    <div style={{ display: "flex" }}>
                      <span id={element[0].hotel.name} style={{ fontWeight: "bold" }}>{element[0].hotel.name}</span> <span> &nbsp; (Distance: {(Number((element[0].hotel.distance || 0)) / 1.609).toFixed(2)} miles)</span>
                    </div>
                    <span style={{ marginTop: "0px", fontWeight: "280" }}>{key + " " + element[0].hotel.formatted_phone_number}</span>
                    {element[0].title || element[0].snippet || element[0].link || element[0].date ?
                      <>
                        {element.map((item, index) => (
                          <ColBoot xxl={4} xl={4} lg={4} md={6} sm={12} xs={12} key={index + item?.title}>
                            <CardsBoot onClick={() => {
                              window.open(item?.link, "_blank")
                            }}>
                              <ResultsCard
                                //className={`${PDFLinksWithError?.includes(item?.link) ? "brokenLink" : ""} ${PDFFilesWithError?.includes(item?.link) ? "unreadFile" : ""}`}
                                className=""
                              >
                                <CardsBoot.Body>
                                  <CardsBoot.Title
                                    key={item?.title + index}
                                  >
                                    <ResultsTitle>
                                      {item?.title}
                                    </ResultsTitle>
                                  </CardsBoot.Title>
                                  <CardsBoot.Text key={item?.snippet}>
                                    <ResultsDesc>
                                      {item?.snippet}
                                    </ResultsDesc>
                                  </CardsBoot.Text>
                                  <CardsBoot.Text key={item?.hotel?.name + item?.hotel?.formatted_phone_number}>
                                    {/* <ResultsDesc>
                                    {item?.hotel?.name} | {item?.hotel?.formatted_phone_number}
                                  </ResultsDesc> */}
                                    <a onClick={() => {
                                      window.open(item?.link, "_blank")
                                    }}>
                                      {new URL(item?.link).host}
                                    </a>
                                    {item?.date &&
                                      ` | ${item?.date}`
                                    }
                                  </CardsBoot.Text>
                                </CardsBoot.Body>
                              </ResultsCard>
                            </CardsBoot>
                            <SpaceBetweenResultsCards></SpaceBetweenResultsCards>
                          </ColBoot>
                        ))}
                      </>
                      :
                      <span>No opportunities found related to this competitor.</span>
                    }
                    <HorizontalLine />
                  </>
                ))
              }
            </RowBoot>
          </ContainerBoot>
        </ResultsCardContainer>
      </>
      <ContainerBoot>
        {resultMainHotelOpportunities?.length !== 0 ? (
          <>
            <MainHotelsResultsTitle isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
              <span>Opportunities Main Hotel: {resultMainHotelOpportunities.length} </span> <AiFillCaretDown />
            </MainHotelsResultsTitle>
          </>
        ) : (
          <span></span>
        )}
        {resultMainHotelOpportunities?.length !== 0 ? (
          <ContainerBoot>
            <MainHotelsResultsSection
              isOpen={isOpen}
              className={isOpen ? 'fadeIn' : 'fadeOut'}
            >
              {
                resultMainHotelOpportunities.map((item) => {
                  return (
                    <>
                      <MainHotelsResultsSpan>{item.link}</MainHotelsResultsSpan>
                      <HorizontalLineMain />
                    </>
                  )
                })
              }
            </MainHotelsResultsSection>
          </ContainerBoot>
        ) : (
          <span></span>
        )}
      </ContainerBoot>
    </>
  );
}

const HeaderTitle = styled.section`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  margin-left: 1%;

  & > span {
    color: #ab8585;
    font-size: 1.5rem;
    line-height: 2rem;
    font-family: "Playfair Display", serif;
  }
`;

const YearContainer = styled.span`
  color: #000;
  font-size: 1rem;
  line-height: 2rem;
  font: Open Sans, serif;
  margin-left: 1.5%;
`;

const ResultsCardContainer = styled.div`
  display: flex;
  justify-content: left;
  align-content: space-between;
  flex-wrap: wrap;
  margin-left: 1.5%;
  margin-bottom: 2rem;
`;

const ResultsCard = styled.div`
  min-height: 200px;
  background: ${props => {
    if (props.className.includes('brokenLink')) return '#ffe6e6';
    if (props.className.includes('unreadFile')) return '#fff5e2';
    return '#ffffff';
  }};
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  cursor: pointer;
  .
`;

const ResultsTitle = styled.div`
  color: #575757
  font-weight: 600;;
  font-size: 1rem;
  font-family: 'Open Sans';

`;

const ResultsDesc = styled.div`
  color: #575757;
  font-size: 0.9rem;
  font-family: "Open Sans";
  font-weight: 400;
  line-height: 22px;
`;

const RevOpportunity = styled.div`
  color: ${(props) => (props.opportunity ? "#00A32E" : "#B00101")};
  font-size: 0.9rem;
  font-family: "Open Sans";
  font-weight: 400;
  line-height: 22px;
`;

const ResultsLink = styled.div`
  color: #575757;
  font-size: 0.9rem;
  font-family: "Open Sans";
  font-weight: 400;
  line-height: 22px;
`;

const SpaceBetweenResultsCards = styled.div`
  width: 100%;
  height: 20px;
`;

const NearbyHotelsList = styled.div`
  display: flex;
  flex-flow: column wrap;
  margin-left: 1%;
  margin-bottom: 2rem;
`;

const HorizontalLine = styled.hr`
  border: none;
  border-top: 2px solid grey;
  margin: 5rem 0;
  font-weight: 400;
`;

const HorizontalLineMain = styled.hr`
  border: none;
  border-top: 2px solid grey;
  margin: 1rem 0;
`;

const NearbyHotelsSpan = styled.span`
  &:hover {
    color: #2563EB;
    cursor: pointer;
  }
`;

const MainHotelsResultsSpan = styled.span`
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;
`;

export const MainHotelsResultsSection = styled.div.attrs(props => ({
  classname: props.className,
}))`
	display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
	transition: 1s;
	opacity: 0;

	&.fadeIn {
		opacity: 1;
		transition: 0.2s;
	}

	&.fadeOut {
		opacity: 0;
		transition: 0.2s;
	}
`;

export const MainHotelsResultsTitle = styled.section`
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5rem;
  align-items: center;
	justify-content: space-between;
	cursor: pointer;

  & > span {
    color: #ab8585;
    font-size: 1.5rem;
    line-height: 2rem;
    font-family: "Playfair Display", serif;
  }

  > svg {
		transform: ${({ isOpen }) => (isOpen ? 'rotate(-180deg)' : 'none')};
		transition: 0.2s;
	}
`;
