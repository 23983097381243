import React from "react";
import styled from "styled-components";

export default function Button({ onClick, label, variant }) {
  return (
    <ButtonComponent
      onClick={onClick}
      {...variant}
      style={styles[variant] || styles["primary"]}
    >
      {label}
    </ButtonComponent>
  );
}

const styles = {
  primary: {
    backgroundColor: "#575757",
    color: "#FFF",
    border: "none",
    hoverBackgroundColor: "#6D6D6D",
  },
  secondary: {
    backgroundColor: "#FFF",
    color: "#575757",
    border: "1px solid #E6E6E6",
    hoverBackgroundColor: "#FFF",
  },
};

const ButtonComponent = styled.button`
  background-color: ${(props) => props.style.backgroundColor};
  color: ${(props) => props.style.color};
  border: ${(props) => props.style.border};
  width: 18.75rem;
  font-size: 1rem;
  font-family: "Open Sans", serif;
  font-weight: 600;
  line-height: 2rem;
  text-align: center;
  box-shadow: 0px 4px 17px -2px rgba(0, 0, 0, 0.08);
  padding: 0.25rem;
  cursor: pointer;
  &:hover {
    background-color: ${(props) => props.style.hoverBackgroundColor};
    transition: 2s;
  }
`;
