import React, { useState, useEffect, useCallback, useRef } from "react";
import styled from "styled-components";
import Button from "../../../components/Button";
import DatePicker from "react-datepicker";
import InputComponent from "../../../components/Input";
import Tooltip from "../../../components/Tooltip";
import getHotels from "../../../providers/Rates/Hotels";
import getHotelsKayak from "../../../providers/Rates/KayakHotels";
import getRates from "../../../providers/Rates/Rates";
import getRatesKayak from "../../../providers/Rates/Kayak";
import "react-datepicker/dist/react-datepicker.css";
import { debounce } from "lodash";
import {
  AiFillPlusSquare,
  AiFillMinusSquare,
  AiOutlineLoading3Quarters,
} from "react-icons/ai";
import { toast } from "react-toastify";
import Chart from "react-apexcharts";
import moment from "moment";
import ReCAPTCHA from "react-google-recaptcha";
import { addDays, compareDesc } from "date-fns";
import axios from "axios";
import { useParams } from "react-router-dom";

export default function TableDates({ nextPage, handleError }) {
  const defaultLoadingMessages = {
    message1:
      "Now Bezla is searching thousands of websites to detect the lowest rate for each hotel selected.",
    message2:
      "Once the results are shown, you may point your cursor to each date and determine where Bezla is capturing the rate shown on the table.",
  };

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [hotelQuery, setHotelQuery] = useState();
  const [hotelOptions, setHotelOptions] = useState([]);
  const [comparisonList, setComparisonList] = useState([]);
  const [comparisonCloneList, setComparisonCloneList] = useState([]);
  const [originsList, setOriginsList] = useState([]);
  const [resultsHotels, setResultsHotels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingHotels, setIsLoadingHotels] = useState(false);
  const [loadingHotels, setLoadingHotels] = useState(false);
  const graphRef = useRef(null);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [captchaIsVisible, setCaptchaIsVisible] = useState(true);
  
  const { provider } = useParams();

  let previousLoadingMessage = defaultLoadingMessages.message2;
  let currentLoadingMessage = defaultLoadingMessages.message1;
  let nextLoadingMessage = defaultLoadingMessages.message2;

  const handleChangeHotelQuery = async (e) => {
    // set hotel query
    setHotelQuery(e);
    // use Debounce for API calls and not overloading the server
    await debounceGetHotels(e);
    setLoadingHotels(true);
    setIsLoadingHotels(true);
  };

  useEffect(() => {
    if (hotelOptions && hotelOptions.length !== 0) {
      setIsLoadingHotels(false);
    } else {
      setIsLoadingHotels(true);
    }
  }, [hotelOptions, isLoadingHotels]);

  // debounce function
  const debounceGetHotels = useCallback(
    debounce(
      (e) => {
        provider === 't' ?
        getHotels(e).then((res) => {
          setHotelOptions(res);
          setLoadingHotels(false);
        })
        :
        getHotelsKayak({ query: e }).then((res) => {
          const result = [];
          if (Array.isArray(res)) {
            res.forEach((hotelResults) => {
              result.push({
                name: hotelResults.displayname,
                shortName: hotelResults.hotelname,
                address: {
                  address: hotelResults.fullAddress,
                },
                coordenates: hotelResults.lat + "," + hotelResults.lng,
                hotelId: hotelResults.hid,
              });
            });
            setHotelOptions(result);
            setLoadingHotels(false);
          }
        })
      },1000
    ),
    []
  );

  const addToComparisonList = async (e, obj) => {
    e.preventDefault();
    const removedHotelOptions = hotelOptions.filter(
      (item) => item.hotelId !== obj.hotelId
    );
    await setHotelOptions([...removedHotelOptions]);
    await setComparisonList([...comparisonList, obj]);
  };

  const removeFromComparisonList = async (e, obj) => {
    e.preventDefault();
    const removedComparationList = comparisonList.filter(
      (item) => item.hotelId !== obj.hotelId
    );
    await setComparisonList([...removedComparationList]);
    await setHotelOptions([...hotelOptions, obj]);
  };

  const onSubmitButton = async () => {
    if (captchaValue) {
      const res = await verifyCaptcha(captchaValue);
      setCaptchaIsVisible(false);
      if (res) {
        await runGraphCalculations()
          .then((res) => setCaptchaIsVisible(true))
          .catch((err) => setCaptchaIsVisible(true));
      } else {
        setCaptchaIsVisible(true);
        toast.warn("Please revalidate the reCAPTCHA");
      }
    } else {
      setCaptchaIsVisible(true);
      toast.warn("Please fill the reCAPTCHA.");
    }
  };

  const verifyCaptcha = async (id) => {
    let requestURL = window?.location.href.includes("localhost")
      ? "http://localhost:5001/rates-bb81c/us-central1/getCaptcha"
      : "https://us-central1-budget-effc0.cloudfunctions.net/getCaptchaBudget";

    try {
      const { data } = await axios.post(requestURL, {
        token: id,
      });

      return data.success ? true : data === "OK" ? true : false;
    } catch (error) {
      return false;
    }
  };

  const runGraphCalculations = async () => {
    try {
      if (comparisonList?.length < 1) {
        throw new Error("You must select at least one hotel.");
      }

      if (!startDate) {
        throw new Error("Start date invalid.");
      }

      if (!endDate) {
        throw new Error("End date invalid.");
      }

      if (new Date(startDate).getTime() > new Date(endDate).getTime()) {
        throw new Error("The check_in is greaten than check_out.");
      }

      await setComparisonCloneList(comparisonList);

      setIsLoading(true);

      const id = setInterval(() => {
        previousLoadingMessage = currentLoadingMessage;
        currentLoadingMessage = nextLoadingMessage;
        nextLoadingMessage = previousLoadingMessage;
        document.getElementById("loadingMessage").innerHTML =
          currentLoadingMessage;
      }, 5000);

      var rates;
      if(provider === 't'){
        rates = await getRates(
          comparisonList.map((item) => item.hotelId),
          startDate.toISOString(),
          endDate.toISOString()
        );
      }else{
        var str = startDate.toISOString();
        const start = str.slice(0, str.indexOf("T"));
        str = endDate.toISOString();
        const end = str.slice(0, str.indexOf("T"));
        rates = await getRatesKayak(
          comparisonList.map((item) => {
            return { hotelId: item.hotelId, hotelName: item.name };
          }),
          start,
          end
        );
      }
      
      resetLoadingMessages();
      setIsLoading(false);
      clearInterval(id);
      console.log(rates);
      await setResultsHotels(rates);

      const origins = rates.map((item, index) => {
        let name =
          comparisonList.length > 0
            ? comparisonList.filter((cpl) => cpl.hotelId === item.hotelId)[0]
                ?.shortName
            : "";
        let codigos = item?.dates
          ? item?.dates.map((data, index) => {
              return data ? data?.code.filter((c) => c).join("; ") : "";
            })
          : [];
        return {
          name: name,
          codes: codigos,
        };
      });

      setOriginsList(origins);

      graphRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    } catch (err) {
      console.error(err);
      toast.error(err.message);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const resetLoadingMessages = () => {
    currentLoadingMessage = defaultLoadingMessages.message1;
    nextLoadingMessage = defaultLoadingMessages.message2;
  };

  return (
    <Container>
      <InputContainer>
        <InputDates>
          <DateFieldContainer>
            <span>Start Date</span>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              minDate={new Date()}
            />
          </DateFieldContainer>
          <DateFieldContainer>
            <span>End Date</span>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              maxDate={addDays(startDate, 89)}
            />
          </DateFieldContainer>
          <div></div>
        </InputDates>
        <InputHotelSearch>
          <span>Search Hotel</span>
          <InputComponent
            variant="secondary"
            placeholder={"Type the hotel or location..."}
            onChange={(e) => handleChangeHotelQuery(e.target.value)}
            value={hotelQuery}
          />
        </InputHotelSearch>
      </InputContainer>
      <TableContainer>
        <Table>
          <THead>
            <tr>
              <td>
                <h3>Matching Results Found</h3>
              </td>
            </tr>
            <HeaderTableRow>
              <td>
                <strong>Name</strong>
              </td>
              <td>
                <strong>Location</strong>
              </td>
            </HeaderTableRow>
          </THead>
          <TBody>
            {loadingHotels ? (
              <>
                <LoadingHotel>
                  <AiOutlineLoading3Quarters size={25} />
                  <strong>Loading...</strong>
                </LoadingHotel>
              </>
            ) : (
              <>
                {Array.isArray(hotelOptions) && !isLoadingHotels ? (
                  hotelOptions?.map((item, index) => {
                    return (
                      <ResultsTableRow key={`${item.shortName} - ${index}`}>
                        <td>
                          <span>{item.shortName}</span>
                        </td>
                        <td>
                          {provider === 't' ? 
                          <span>
                            {item.address.address} - {item.address.city},{" "}
                            {item.address.country}
                          </span>
                          :
                          <span>
                            {item.address.address}
                          </span>
                          }
                        </td>
                        <td>
                          <button onClick={(e) => addToComparisonList(e, item)}>
                            <AiFillPlusSquare size={28} />
                          </button>
                        </td>
                      </ResultsTableRow>
                    );
                  })
                ) : (
                  <td>The table is empty.</td>
                )}
              </>
            )}
          </TBody>
        </Table>
        <SecondaryTable>
          <THead>
            <tr>
              <td>
                <h3>Hotel Comparison List</h3>
              </td>
            </tr>
            <ResultsTableRow>
              <td>
                <span>
                  <strong>Name</strong>
                </span>
              </td>
            </ResultsTableRow>
          </THead>
          <TBody>
            {comparisonList &&
              comparisonList?.map((item, index) => {
                return (
                  <ResultsTableRow key={`${item.shortName} - ${index}`}>
                    <td>
                      <span>
                        {item.name} ({item.address.address})
                      </span>
                    </td>
                    <td>
                      <button
                        onClick={(e) => removeFromComparisonList(e, item)}
                      >
                        <AiFillMinusSquare size={28} />
                      </button>
                    </td>
                  </ResultsTableRow>
                );
              })}
          </TBody>
        </SecondaryTable>
      </TableContainer>
      <CaptchaContainer>
        {captchaIsVisible && (
          <ReCAPTCHA
            sitekey="6Ld_S8MdAAAAACAkzMl0Pfsc3eMUDGXWjv2C_lgT"
            onChange={(item) => setCaptchaValue(item)}
            onErrored={() => setCaptchaValue(null)}
          />
        )}
      </CaptchaContainer>
      <ButtonsContainer>
        <Button label="RUN" onClick={() => onSubmitButton()} />
      </ButtonsContainer>
      {resultsHotels.length > 0 && (
        <ChartContainer ref={graphRef}>
          <h3>Price charts</h3>
          <Chart
            options={{
              colors: [
                "#008FFB",
                "#00E396",
                "#FEB019",
                "#FF4560",
                "#775DD0",
                "#2E8B57",
                "#8B0000",
                "#191970",
                "#FF0000",
                "#FFFF00",
              ],
              chart: {
                id: "basic-bar",
              },
              xaxis: {
                categories: resultsHotels[0]?.dates.map((item) => {
                  return moment(item.date).format(`MM/DD/YYYY`);
                }),
              },
              yaxis: {
                title: {
                  text: "Price",
                },
              },

              animations: {
                enabled: true,
                easing: "linear",
                dynamicAnimation: {
                  speed: 1000,
                },
              },
              tooltip: {
                followCursor: true,
                onDatasetHover: {
                  highlightDataSeries: true,
                },
                custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                  return `<div class="arrow_box" style="diplay:flex;flex-direction:column;width:calc(auto+ 50px);margin:10px">
                      <span style="display: flex;flex-direction:row">
                        <div
                          style="margin:auto;width:10px;height:10px;background-color:${
                            w.globals.colors[seriesIndex]
                          }">
                        </div>
                        <div style="margin-left:10px">
                        ${w.config.series[seriesIndex].name}:\t<b>${
                    series[seriesIndex][dataPointIndex]
                  }</b>
                        </div>                        
                      </span>
                      <div>
                        <p>Sites:
                          ${originsList
                            ?.map((el, i) => {
                              return el.name ===
                                w.config.series[seriesIndex].name
                                ? "<b>" +
                                    el.codes[dataPointIndex]
                                      .split(",")
                                      .join("") +
                                    "</b>"
                                : "";
                            })
                            .join("")
                            .replace(", ", "")}
                          </p>
                      </div>
                    </div>`;
                },
              },
            }}
            series={resultsHotels.map((item, i) => {
              return {
                name:
                  comparisonCloneList.length > 0
                    ? comparisonCloneList?.filter(
                        (cpl) => cpl.hotelId === item.hotelId
                      )[0]?.shortName
                    : "",
                data: item.dates.map((arrItem) => arrItem.rate),
              };
            })}
            type="line"
            width="100%"
            height="100%"
          />
        </ChartContainer>
      )}
      {resultsHotels.length > 0 && (
        <TableContainer>
          <ResultsTable>
            <THeadPrice>
              <tr>
                <td>
                  <h3>Lowest prices for selected date range and hotels</h3>
                </td>
              </tr>
              <TableRowPrice>
                <td className="bold">Date</td>
                {resultsHotels &&
                  resultsHotels.map((item, index) => {
                    return (
                      <td className="bold" key={index}>
                        {comparisonCloneList.length
                          ? comparisonCloneList?.filter(
                              (cpl) => cpl.hotelId === item.hotelId
                            )[0]?.shortName
                          : ""}
                      </td>
                    );
                  })}
              </TableRowPrice>
            </THeadPrice>
            <TBodyPrice>
              {resultsHotels[0]?.dates.map((item) => (
                <TableRowPrice
                  className={
                    moment(item.date).isoWeekday() === 5 ||
                    moment(item.date).isoWeekday() === 6
                      ? "highlighted"
                      : ""
                  }
                  key={`${moment(item.date).format(`MM/DD/YYYY`)}`}
                >
                  <td className="bold">
                    <span>{moment(item.date).format(`MM/DD/YYYY`)}</span>
                  </td>
                  {resultsHotels.map((hotelValue, index) => {
                    return (
                      <td
                        className="center hotel-price-item"
                        key={`td-${index}`}
                      >
                        <Tooltip
                          content={
                            <p>
                              Sites:{" "}
                              {hotelValue?.dates
                                ?.find((dates) => dates?.date === item.date)
                                ?.code?.slice(0, 5)
                                ?.join("; ")
                                ?.replace(", ", "")}
                            </p>
                          }
                        >
                          <span>
                            {hotelValue.dates.filter(
                              (arrData) => arrData.date === item.date
                            )[0].rate !== 0
                              ? "$" +
                                hotelValue.dates.filter(
                                  (arrData) => arrData.date === item.date
                                )[0].rate
                              : "No Availability"}
                          </span>
                        </Tooltip>
                      </td>
                    );
                  })}
                </TableRowPrice>
              ))}
            </TBodyPrice>
          </ResultsTable>
        </TableContainer>
      )}
      {isLoading && (
        <LoadingModal>
          <div>
            <AiOutlineLoading3Quarters />
            <strong>Loading...</strong>
            <span id="modal-loading-percent"></span>
            <h3 id="loadingMessage">{defaultLoadingMessages.message1}</h3>
          </div>
        </LoadingModal>
      )}
    </Container>
  );
}

const InputContainer = styled.div`
  font: Open Sans, serif;
  color: #575757;
  width: 100%;
  margin-bottom: 1rem;
`;

const InputDates = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 2.5rem;

  & > span {
    margin-bottom: 1rem;
  }
`;

const InputHotelSearch = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 2.5rem;

  & > span {
    margin-bottom: 0.5rem;
  }
`;

const Table = styled.table`
  font: Open Sans, serif;
  color: #575757;
  width: 63%;
  margin-bottom: 2.5rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
`;

const SecondaryTable = styled.table`
  font: Open Sans, serif;
  color: #575757;
  width: 35%;
  margin-bottom: 2.5rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
`;

const ResultsTable = styled.table`
  position: relative;
  display: block;
  font: Open Sans, serif;
  color: #575757;
  width: 100%;
  margin-bottom: 2.5rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
`;

const THead = styled.thead`
  & > tr {
    & > td {
      & > h3 {
        font-size: 1.25rem;
        font-weight: 600;
        margin-top: 0.5rem;
        margin-left: 0.5rem;
      }
    }
  }
`;

const TBody = styled.tbody`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 0.5rem;
  height: 25rem;
  overflow-y: scroll;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-bottom: 10rem;

  & > button {
    margin-left: 1.5rem;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TableContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

const HeaderTableRow = styled.tr`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 4rem;
  margin-left: 0.5rem;
  width: 97%;
  border-bottom: 1px solid #e7eaee;
  justify-content: space-between;

  & > td {
    min-width: 19.25rem;
    margin-right: 1rem;
    font-weight: 700;
  }
`;

const ResultsTableRow = styled.tr`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-height: 4rem;
  margin-left: 0.5rem;
  width: 97%;
  border-bottom: 1px solid #e7eaee;

  & > td {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    margin-right: 1rem;

    &:last-child {
      width: auto;
    }
  }

  & > td > button {
    color: #2563eb;
    border-radius: 2px;
    margin-right: 0.3rem;
  }
`;

const DateFieldContainer = styled.div`
  --tw-ring-color: red;
  div.react-datepicker__day--selected {
    background: #333333;
    --tw-ring-color: red;
  }

  div.react-datepicker__day--in-selecting-range {
    background: rgba(51, 51, 51, 0.6);
  }

  div.react-datepicker__day--in-range {
    background: #333333;

    &:after {
      background: red;
    }
  }

  div.react-datepicker__day--highlighted-custom-1 {
    background: #cecece;
  }

  .react-datepicker__input-container input {
    display: block;
    width: 75%;
    font-size: big;
    border: none;
    color: inherit;
  }
`;

const ChartContainer = styled.div`
  width: 100%;
  min-height: 34rem;
  padding: 1rem;
  margin-bottom: 4.5rem;

  display: flex;
  flex-direction: column;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  & > h3 {
    padding: 0 1rem;
    font-size: 1.5rem;
    font-weight: 600;
    color: #575757;
  }
`;

const LoadingModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.4);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #333333;
    max-width: 40%;

    background: white;
    padding: 2rem 2rem 1rem 2rem;

    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;

    & > svg {
      animation: spin 2s linear infinite;

      @keyframes spin {
        0% {
          transform: rotate(0deg) scale(3);
        }
        100% {
          transform: rotate(360deg) scale(3);
        }
      }
    }

    & > strong {
      padding-top: 2rem;
    }

    & > h3 {
      padding-top: 1.5rem;
      padding-bottom: 0.5rem;
      text-align: center;
    }
  }
`;

const LoadingHotel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #333333;

    & > svg {
      animation: spin 2s linear infinite;
      width: 4rem;

      @keyframes spin {
        0% {
          transform: rotate(0deg) scale(3);
        }
        100% {
          transform: rotate(360deg) scale(3);
        }
      }
    }

    & > strong {
      padding-top: 45px;
      padding-left: 7px;
    }
  }
`;

const THeadPrice = styled.thead`
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 0 1rem;
  position: sticky;
  top: 96px;
  background-color: #ffffff;
  z-index: 1000;

  & tr > td > h3 {
    font-size: 1.5rem;
    font-weight: 600;
  }
`;

const TBodyPrice = styled.tbody`
  height: auto;
  width: auto;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
`;

const TableRowPrice = styled.tr`
  min-height: 4.2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #e7eaee;
  width: 100%;

  & > td {
    width: 12rem;
    padding-right: 1rem;

    &.bold {
      font-weight: 600;
    }

    &.center {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }

  &.highlighted {
    background-color: #e6e3e3;
  }
`;

const CaptchaContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 1rem;
  padding-left: 1.5rem;
`;
