import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

// Pages
import  HomeBudget  from "./pages/Budget/Home";
import HomeEventSearch from "./pages/EventSearch/Home";
import HomeRates from "./pages/Rates/Home";
import HomeSwotAnalysis from "./pages/SwotAnalysis/Home"
import Budget from "./pages/Budget";
import EventSearch from "./pages/EventSearch";
import Rates from "./pages/Rates";
import SwotAnalysis from "./pages/SwotAnalysis"

// CSS Styles
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";

//Env config
// require("dotenv").config();

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      {/* <Route path="/" exact element={<Home />} /> */}
      <Route path="/budget" exact element={<HomeBudget />} />
      <Route path="/budget/:id" exact element={<Budget />} />
      <Route path="/" exact element={<HomeEventSearch />} />
      <Route path="/eventsearch/eventsearch" exact element={<EventSearch />} />
      <Route path="/rates" exact element={<HomeRates />} />
      <Route path="/rates/:provider" exact element={<Rates />} />
      <Route path="/swotAnalysis" exact element={<HomeSwotAnalysis />} />
      <Route path="/swotAnalysis/analysis" exact element={<SwotAnalysis />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
