import axios from "../../utils/requests";

// Define Base Url
const BASE_URL =
window.location.href.indexOf("localhost") > -1
? "http://localhost:8001"
: process.env.REACT_APP_BASE_URL;


//Send request to API
const getHotelInfos = async (params) => {
  try {
    // const url = `${BASE_URL}/features/swotAnalysis/getTravelWeeklyInfo`;
    const url = `${BASE_URL}/features/SWOTAnalysis/getTravelWeeklyInfo`;
    const {
      data: { jobId },
    } = await axios.post(url, {data: params});
    const data = await getJobResult(jobId);
    if (data.includes("Error")) {
      await timeout(10000);
      return await getHotelInfos(params);
    } else {
      return data;
    }
  } catch (err) {
    console.log(err);
    await timeout(10000);
    return await getHotelInfos(params);
  }
};

//Recursive request for updating status of job
const getJobResult = async (jobId, errors = 0) => {
  try {
    // const url = `${BASE_URL}/features/swotAnalysis/getJobId`;
    const url = `${BASE_URL}/features/getJobId`;

    const { data } = await axios.post(url, {jobId});
    if (data.data === "loading...") {
      await timeout(5000);
      return await getJobResult(jobId);
    } else {
      return data;
    }
  } catch (err) {
    return `Error: ${err}`;
  }
};


// Return the data
const getAnalysis = async (hotels) => {
  const data = await getHotelInfos(hotels);
  return data;
};

function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default getAnalysis;
