import axios from "../../utils/requests";

const BASE_URL = window.location.href.indexOf('localhost') > -1
  ? 'http://localhost:9000'
  : 'https://8pwaczlquf.execute-api.us-east-1.amazonaws.com'

const updateCounter = async (query) => {
  try {
    await axios.post(
      `${BASE_URL}/updateCounter`,
      { counter: query }
    );
  } catch (err) {
    console.log(err);
  }
};

export default updateCounter;
