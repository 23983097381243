import React, { useState } from "react";
import styled from "styled-components";
import Header from "../../../components/Header";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const [captchaValue, setCaptchaValue] = useState(null);
  let navigate = useNavigate();

  const onSubmitButton = () => {
    if (captchaValue) {
      navigate(`/budget/${captchaValue}`);
    } else {
      window.alert("Please fill the captcha.");
    }
  };

  return (
    <Container>
      <Header />
      <TitleSection>
        <h1>Budget</h1>
        <span>
          Make more intelligent hotel budgeting decisions with the
          industry-leading Budget from Bezla.
        </span>
      </TitleSection>
      <ButtonSection>
        <div>
          <ReCAPTCHA
            sitekey="6Ld_S8MdAAAAACAkzMl0Pfsc3eMUDGXWjv2C_lgT"
            onChange={(item) => setCaptchaValue(item)}
            onErrored={() => setCaptchaValue(null)}
          />
        </div>
        <button onClick={() => onSubmitButton()} type="button">
          START BUDGET
        </button>
      </ButtonSection>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  padding: 0 2rem;
  background: #343a40;
`;

const TitleSection = styled.section`
  color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 6rem;
  & > h1 {
    text-shadow: 2px 2px 2px rgb(0 0 0 / 10%);
    font-weight: 700;
    line-height: 1.3;
    font-size: 4.5rem;
    font-family: "Open Sans", sans-serif;
  }

  & > span {
    text-shadow: 2px 2px 2px rgb(0 0 0 / 10%);
    font-size: 1.25rem;
    margin-top: 0;
    margin-bottom: 1rem;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
`;

const ButtonSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;

  & > button {
    background-color: white;
    padding: 0.8rem 3rem;
    letter-spacing: 0.1rem;
    font-weight: 700;
    font-size: 1.2rem;
    border-radius: 4px;
    margin-top: 2rem;
  }
`;
