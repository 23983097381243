import axios from "axios";

const BASE_URL = window?.location.href.includes("localhost")
    ? "http://localhost:8000"
    // ? "https://homolog-eventssearch-backend.axion.company"
    : process.env.REACT_APP_BASE_URL;

function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

//Recursive request for updating status of job
const getJobResult = async (jobId, errors = 0) => {
    try {
        const url = `${BASE_URL}/features/getJobId`;
        const { data } = await axios.post(url, { jobId });
        if (data?.data === "loading...") {
            await timeout(5000);
            return await getJobResult(jobId);
        } else {
            return data.data;
        }
    } catch (err) {
        return `Error: ${err}`;
    }
};

const getEvents = async (params) => {
    try {
        const url = `${BASE_URL}/features/eventsSearch/search`;
        const {
            data: { jobId },
        } = await axios.post(url, params);
        const data = await getJobResult(jobId);
        if (!data) {
            await timeout(10000);
            return await getEvents(params);
        } else {
            return data;
        }
    } catch (err) {
        console.log(err);
        await timeout(10000);
        return await getEvents(params);
    }
};

const transformData = (data, selectedDistance) => {
    console.log("teste main hotel: ", data.flattenedResultsMainHotel);
    const aggregated = data?.searchResults.reduce((result, element) => {
        const category = element.hotel.formatted_address + '|' + element.hotel.name;

        if (!result[category]) {
            result[category] = [];
        }

        result[category].push(element);

        return result;
    }, {});
    const hotelsWithOpportunities = Object.keys(aggregated);
    let allHotels = aggregated;
    data?.hotelDetails?.forEach(place => {
        const hasOpportunities = hotelsWithOpportunities.filter(item => item === place.formatted_address)
        if (hasOpportunities.length === 0) {
            allHotels = { ...allHotels, [place.formatted_address]: [{ hotel: place }] }
        }
    })
    return {
        places: data?.hotelDetails,
        unfilteredNearbyHotels: data?.unfilterdHotelDetails,
        flattenedResultsMainHotel: data?.flattenedResultsMainHotel,
        resultCustomSearch: { length: data?.searchResults.length, formattedArray: allHotels },
        mainHotelDetails: data?.mainHotelDetails,
        PDFLinksWithError: data?.PDFLinksWithError,
        PDFFilesWithError: data?.PDFFilesWithError,
    }
}
const searchEvents = async (params) => {
    const data = await getEvents(params);
    const parsedData = transformData(data, params.radius);
    return parsedData;
};

export { searchEvents };