import React, { useState, useEffect, useCallback, useRef } from "react";
import styled from "styled-components";
import Button from "../../../components/Button";
import InputComponent from "../../../components/Input";
import Tooltip from "../../../components/Tooltip";
import getHotels from "../../../providers/SwotAnalysis/Hotels";
import getAnalysis from "../../../providers/SwotAnalysis/Analysis";
import "react-datepicker/dist/react-datepicker.css";
import { debounce } from "lodash";
import {
  AiFillPlusSquare,
  AiFillMinusSquare,
  AiOutlineLoading3Quarters,
} from "react-icons/ai";
import { toast } from "react-toastify";
import Chart from "react-apexcharts";
import moment from "moment";
import ReCAPTCHA from "react-google-recaptcha";
import { addDays, compareDesc } from "date-fns";
import axios from "axios";
import { useParams } from "react-router-dom";

export default function TableDates({ nextPage, handleError }) {
  const defaultLoadingMessages = {
    message1:
      "Bezla is now producing a report that enables you to compare the strengths, weaknesses, opportunities, and threats (SWOT) of each hotel.",
    message2:
      "Bezla is now producing a report that enables you to compare the strengths, weaknesses, opportunities, and threats (SWOT) of each hotel.",
  };

  const [hotelQuery, setHotelQuery] = useState();
  const [hotelOptions, setHotelOptions] = useState([]);
  const [comparisonList, setComparisonList] = useState([]);
  const [resultsHotels, setResultsHotels] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingHotels, setIsLoadingHotels] = useState(false);
  const [loadingHotels, setLoadingHotels] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [captchaIsVisible, setCaptchaIsVisible] = useState(true);
  const [tableInfoKeys, setTableInfoKeys] = useState([]);

  let previousLoadingMessage = defaultLoadingMessages.message2;
  let currentLoadingMessage = defaultLoadingMessages.message1;
  let nextLoadingMessage = defaultLoadingMessages.message2;

  const handleChangeHotelQuery = async (e) => {
    // set hotel query
    setHotelQuery(e);
    // use Debounce for API calls and not overloading the server
    await debounceGetHotels(e);
    setLoadingHotels(true);
    setIsLoadingHotels(true);
  };

  useEffect(() => {
    if (hotelOptions && hotelOptions.length !== 0) {
      setIsLoadingHotels(false);
    } else {
      setIsLoadingHotels(true);
    }
  }, [hotelOptions, isLoadingHotels]);

  // debounce function
  const debounceGetHotels = useCallback(
    debounce(
      (e) => {
        getHotels(e).then((res) => {
          setHotelOptions(res);
          setLoadingHotels(false);
        })
      },1000
    ),
    []
  );

  const addToComparisonList = async (e, obj) => {
    e.preventDefault();
    if(comparisonList.length === 6){
      toast.warn("You can only compare 6 hotels at a time");
    }else{
      const removedHotelOptions = hotelOptions.filter(
        (item) => item.Key !== obj.Key
      );
      await setHotelOptions([...removedHotelOptions]);
      await setComparisonList([...comparisonList, obj]);
    }

  };

  const removeFromComparisonList = async (e, obj) => {
    e.preventDefault();
    const removedComparationList = comparisonList.filter(
      (item) => item.Key !== obj.Key
    );
    await setComparisonList([...removedComparationList]);
    await setHotelOptions([...hotelOptions, obj]);
  };

  const onSubmitButton = async () => {
    // if (captchaValue) {
    //   const res = await verifyCaptcha(captchaValue);
    //   setCaptchaIsVisible(false);
    //   if (res) {
        await runGraphCalculations()
          .then((res) => setCaptchaIsVisible(true))
          .catch((err) => setCaptchaIsVisible(true));
    //   } else {
    //     setCaptchaIsVisible(true);
    //     toast.warn("Please revalidate the reCAPTCHA");
    //   }
    // } else {
    //   setCaptchaIsVisible(true);
    //   toast.warn("Please fill the reCAPTCHA.");
    // }
  };

  const verifyCaptcha = async (id) => {
    let requestURL = window?.location.href.includes("localhost")
      ? "http://localhost:5001/rates-bb81c/us-central1/getCaptcha"
      : "https://us-central1-budget-effc0.cloudfunctions.net/getCaptchaBudget";

    try {
      const { data } = await axios.post(requestURL, {
        token: id,
      });

      return data.success ? true : data === "OK" ? true : false;
    } catch (error) {
      return false;
    }
  };

  const runGraphCalculations = async () => {
    try {
      if (comparisonList?.length < 1) {
        throw new Error("You must select at least one hotel.");
      }

      setIsLoading(true);

      const id = setInterval(() => {
        previousLoadingMessage = currentLoadingMessage;
        currentLoadingMessage = nextLoadingMessage;
        nextLoadingMessage = previousLoadingMessage;
        document.getElementById("loadingMessage").innerHTML =
          currentLoadingMessage;
      }, 5000);

      var result;
      result = await getAnalysis(comparisonList);

      const _ratesAndPolicies = Object.keys(result[0].ratesAndPolicies).map(item => {return {key: `ratesAndPolicies.${item}`, name: item}})
      const _amenities = Object.keys(result[0].amenities).map(item => {return {key: `amenities.${item}`, name: item}})
      const _recreation = Object.keys(result[0].recreation).map(item => {return {key: `recreation.${item}`, name: item}})
      const _servicesAndFacilities = Object.keys(result[0].servicesAndFacilities).map(item => {return {key: `servicesAndFacilities.${item}`, name: item}})
      // const _meetingServices = Object.keys(result[0].meetingServices).map(item => {return {key: `meetingServices.${item}`, name: item}})

      setTableInfoKeys([
        {key: "google-rating", name: "Google Rating"},
        {key: "tripAdvisor-rating", name: "TripAdvisor Rating"},
        {key: "yearBuild", name: "Year Build"},
        {key: "yearLastRenovated", name: "Year Last Renovated"},
        {key: "checkInTime", name: "Check in Time"},
        {key: "checkOutTime", name: "Check out Time"},
        {key: "numberOfFloors", name: "Number of Floors"},
        {key: "rooms", name: "Rooms"},
        ..._ratesAndPolicies,
        ..._amenities,
        ..._recreation,
        ..._servicesAndFacilities,
        {key: "meetingCapacity", name: "Meeting Capacity"},
        {key: "meetingSpace", name: "Meeting Space"},
        {key: "exhibitSpace", name: "Exhibit Space"},
        {key: "largestMeetingRoomCapacity", name: "Largest Meeting Room Capacity"},
        // ..._meetingServices,
        {key: "phone", name: "Contacts"},
        {key: "hotelEmail", name: "Hotel Email"},
        {key: "hotelWebsite", name: "Hotel Website"},
      ]);

      resetLoadingMessages();
      setIsLoading(false);
      clearInterval(id);
      setResultsHotels(result);

      const element = document.getElementById("resultsTable");
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: "start" });
      }
    } catch (err) {
      console.error(err);
      toast.error(err.message);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    document.title = "SWOT Analysis"
  }, []);

  const resetLoadingMessages = () => {
    currentLoadingMessage = defaultLoadingMessages.message1;
    nextLoadingMessage = defaultLoadingMessages.message2;
  };

  function renderPropertyValue(propertyPath, data) {
    const keys = propertyPath.split(".");
    let value = keys.reduce((prev, key) => (prev || {})[key], data);
  
    if (!value || value === "undefined") {
      value = "Not Available";
    }
    return <span>{value.trim()}</span>;
  }

  return (
    // <div style={{display: 'flex', flexDirection: 'column'}}>
    <Container>
      <InputContainer>
        <InputHotelSearch>
          <span>Search Hotel</span>
          <InputComponent
            variant="secondary"
            placeholder={"Type the hotel or location..."}
            onChange={(e) => handleChangeHotelQuery(e.target.value)}
            value={hotelQuery}
          />
        </InputHotelSearch>
      </InputContainer>
      <TableContainer>
        <Table>
          <THead>
            <tr>
              <td>
                <h3>Matching Results Found</h3>
              </td>
            </tr>
            <HeaderTableRow>
              <td>
                <strong>Name</strong>
              </td>
              <td>
                <strong>Location</strong>
              </td>
            </HeaderTableRow>
          </THead>
          <TBody>
            {loadingHotels ? (
              <>
                <LoadingHotel>
                  <AiOutlineLoading3Quarters size={25} />
                  <strong>Loading...</strong>
                </LoadingHotel>
              </>
            ) : (
              <>
                {Array.isArray(hotelOptions) && !isLoadingHotels ? (
                  hotelOptions?.map((item, index) => {
                    return (
                      <ResultsTableRow key={`${item.Name} - ${index}`}>
                        <td>
                          <span>{item.Data.split("-")[0]}</span>
                        </td>
                        <td>
                          <span>
                            {item.fullAddress}
                          </span>
                        </td>
                        <td>
                          <button onClick={(e) => addToComparisonList(e, item)}>
                            <AiFillPlusSquare size={28} />
                          </button>
                        </td>
                      </ResultsTableRow>
                    );
                  })
                ) : (
                  <td>The table is empty.</td>
                )}
              </>
            )}
          </TBody>
        </Table>
        <SecondaryTable>
          <THead>
            <tr>
              <td>
                <h3>Hotel Comparison List</h3>
              </td>
            </tr>
            <ResultsTableRow>
              <td>
                <span>
                  <strong>Name</strong>
                </span>
              </td>
            </ResultsTableRow>
          </THead>
          <TBody>
            {comparisonList &&
              comparisonList?.map((item, index) => {
                return (
                  <ResultsTableRow key={`${item.shortName} - ${index}`}>
                    <td>
                      <span>
                        {item.Data.split("-")[0]} ({item.fullAddress})
                      </span>
                    </td>
                    <td>
                      <button
                        onClick={(e) => removeFromComparisonList(e, item)}
                      >
                        <AiFillMinusSquare size={28} />
                      </button>
                    </td>
                  </ResultsTableRow>
                );
              })}
          </TBody>
        </SecondaryTable>
      </TableContainer>
      <CaptchaContainer>
        {captchaIsVisible && (
          <ReCAPTCHA
            sitekey="6Ld_S8MdAAAAACAkzMl0Pfsc3eMUDGXWjv2C_lgT"
            onChange={(item) => setCaptchaValue(item)}
            onErrored={() => setCaptchaValue(null)}
          />
        )}
      </CaptchaContainer>
      <ButtonsContainer>
        <Button label="RUN" onClick={() => onSubmitButton()} />
      </ButtonsContainer>
      {isLoading && (
        <LoadingModal>
          <div>
            <AiOutlineLoading3Quarters />
            <strong>Loading...</strong>
            <span id="modal-loading-percent"></span>
            <h3 id="loadingMessage">{defaultLoadingMessages.message1}</h3>
          </div>
        </LoadingModal>
      )}
      {resultsHotels.length > 0 && (
        <TableContainer id="resultsTable">
          <ResultsTable>
            <THeadPrice>
              <tr>
                <td>
                  <h3>Information about the selected hotels</h3>
                </td>
              </tr>
              <TableRowPrice>
                <td className="bold first">Information</td>
                {resultsHotels &&
                  resultsHotels.map((item, index) => {
                    return (
                      <td className="bold" key={index}>
                        {item.hotel.Data.split("-")[0]}
                      </td>
                    );
                  })}
              </TableRowPrice>
            </THeadPrice>
            <TBodyPrice>
              {tableInfoKeys?.map((item, index) => (
                <TableRowPrice>
                  <td className="bold first">
                    <span>{tableInfoKeys[index].name}</span>
                  </td>
                  {resultsHotels.map((hotelInfo) => {
                    return (
                      <td
                      className="center hotel-price-item"
                      >
                        {
                          tableInfoKeys[index].key === 'title' ?
                            null
                            :
                            renderPropertyValue(tableInfoKeys[index].key, hotelInfo)
                        }
                      </td>
                    );
                  })}
                </TableRowPrice>
              ))}
            </TBodyPrice>
          </ResultsTable>
        </TableContainer>
      )}
    </Container>
  // </div>
  );
}

const InputContainer = styled.div`
  font: Open Sans, serif;
  color: #575757;
  width: 100%;
  margin-bottom: 1rem;
  justify-content: flex-start;
`;

const InputDates = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 2.5rem;

  & > span {
    margin-bottom: 1rem;
  }
`;

const InputHotelSearch = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 2.5rem;

  & > span {
    margin-bottom: 0.5rem;
  }
`;

const Table = styled.table`
  font: Open Sans, serif;
  color: #575757;
  width: 63%;
  margin-bottom: 2.5rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
`;

const SecondaryTable = styled.table`
  font: Open Sans, serif;
  color: #575757;
  width: 35%;
  margin-bottom: 2.5rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
`;

const ResultsTable = styled.table`
  position: relative;
  display: block;
  font: Open Sans, serif;
  color: #575757;
  width: 100%;
  margin-bottom: 2.5rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
`;

const THead = styled.thead`
  & > tr {
    & > td {
      & > h3 {
        font-size: 1.25rem;
        font-weight: 600;
        margin-top: 0.5rem;
        margin-left: 0.5rem;
      }
    }
  }
`;

const TBody = styled.tbody`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 0.5rem;
  height: 25rem;
  overflow-y: scroll;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  margin-bottom: 10rem;

  & > button {
    margin-left: 1.5rem;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const TableContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;

const HeaderTableRow = styled.tr`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 4rem;
  margin-left: 0.5rem;
  width: 97%;
  border-bottom: 1px solid #e7eaee;
  justify-content: space-between;

  & > td {
    min-width: 19.25rem;
    margin-right: 1rem;
    font-weight: 700;
  }
`;

const ResultsTableRow = styled.tr`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-height: 4rem;
  margin-left: 0.5rem;
  width: 97%;
  border-bottom: 1px solid #e7eaee;

  & > td {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    margin-right: 1rem;

    &:last-child {
      width: auto;
    }
  }

  & > td > button {
    color: #2563eb;
    border-radius: 2px;
    margin-right: 0.3rem;
  }
`;

const DateFieldContainer = styled.div`
  --tw-ring-color: red;
  div.react-datepicker__day--selected {
    background: #333333;
    --tw-ring-color: red;
  }

  div.react-datepicker__day--in-selecting-range {
    background: rgba(51, 51, 51, 0.6);
  }

  div.react-datepicker__day--in-range {
    background: #333333;

    &:after {
      background: red;
    }
  }

  div.react-datepicker__day--highlighted-custom-1 {
    background: #cecece;
  }
`;

const ChartContainer = styled.div`
  width: 100%;
  min-height: 34rem;
  padding: 1rem;
  margin-bottom: 4.5rem;

  display: flex;
  flex-direction: column;

  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  & > h3 {
    padding: 0 1rem;
    font-size: 1.5rem;
    font-weight: 600;
    color: #575757;
  }
`;

const LoadingModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.4);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #333333;
    max-width: 40%;

    background: white;
    padding: 2rem 2rem 1rem 2rem;

    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;

    & > svg {
      animation: spin 2s linear infinite;

      @keyframes spin {
        0% {
          transform: rotate(0deg) scale(3);
        }
        100% {
          transform: rotate(360deg) scale(3);
        }
      }
    }

    & > strong {
      padding-top: 2rem;
    }

    & > h3 {
      padding-top: 1.5rem;
      padding-bottom: 0.5rem;
      text-align: center;
    }
  }
`;

const LoadingHotel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #333333;

    & > svg {
      animation: spin 2s linear infinite;
      width: 4rem;

      @keyframes spin {
        0% {
          transform: rotate(0deg) scale(3);
        }
        100% {
          transform: rotate(360deg) scale(3);
        }
      }
    }

    & > strong {
      padding-top: 45px;
      padding-left: 7px;
    }
  }
`;

const THeadPrice = styled.thead`
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 0 1rem;
  position: sticky;
  top: 96px;
  background-color: #ffffff;
  z-index: 1000;

  & tr > td > h3 {
    font-size: 1.5rem;
    font-weight: 600;
  }
`;

const TBodyPrice = styled.tbody`
  height: auto;
  width: auto;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
  table-layout: fixed;
`;

const TableRowPrice = styled.tr`
  min-height: 4.2rem;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-bottom: 1px solid #e7eaee;
  margin-top: 16px;
  width: 100%;

  & > td {
    width: 15rem;
    padding-right: 1rem;

    &.bold {
      font-weight: 600;
    }

    &.center {
      display: flex;
      flex-direction: row;
      justify-content: center;
      overflow-wrap: anywhere;
    }
    &.first {
     width: 13rem;
    }
  }

  &.highlighted {
    background-color: #e6e3e3;
  }
`;

const CaptchaContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 1rem;
  padding-left: 1.5rem;
`;
