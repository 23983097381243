import axios from "../../utils/requests";

const BASE_URL = window.location.href.indexOf('localhost') > -1
? 'http://localhost:8001'
: process.env.REACT_APP_BASE_URL;

const getHotels = async (query = "") => {
  try {
    // const { data } = await axios.post(`${BASE_URL}/features/hotelSearch/search`,{ query, provider: "travelWeekly" });
    const { data } = await axios.post(`${BASE_URL}/features/SWOTAnalysis/search`,{ query, provider: "travelWeekly" });
    return data.data;
  } catch (err) {
    console.log(err);
  }
};

export default getHotels;
