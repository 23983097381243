import axios from "../../utils/requests";

const BASE_URL = window.location.href.indexOf('localhost') > -1
  ? 'http://localhost:9000'
  : 'https://8pwaczlquf.execute-api.us-east-1.amazonaws.com'

const getCounter = async () => {
  try {
    const { data } = await axios.get(
      `${BASE_URL}/getCounter`,
    );

    return data;
  } catch (err) {
    console.log(err);
  }
};

export default getCounter;
