import React, { useState } from "react";
import styled from "styled-components";
import Header from "../../../components/Header";
import { useNavigate } from "react-router-dom";
import getCounter from "../../../providers/Rates/GetCounter";
import updateCounter from "../../../providers/Rates/UpdateCounter";

export default function Home() {
  let navigate = useNavigate();
  
  const onSubmitButton = async () => {
    let counter = await getCounter();

    if(counter % 2 == 0) {
      counter++
      updateCounter(counter);
      navigate(`/rates/k`);
    } else {
      counter++
      updateCounter(counter);
      navigate(`/rates/t`);
    }
  };

  return (
    <Container>
      <Header />
      <TitleSection>
        <h1>Rates</h1>
        <span>
          Make more intelligent hotel pricing decisions. Get real-time rate comparison data with the <br />
          industry-leading hotel rate shopper from Bezla.
        </span>
      </TitleSection>
      <ButtonSection>
        <button onClick={() => onSubmitButton()} type="button">
          START RATES
        </button>
      </ButtonSection>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  padding: 0 2rem;
  background: #343a40;
  background: linear-gradient(180deg, rgba(52,58,64,1) 0%, rgba(32,34,36,1) 60%, rgba(22,22,22,1) 75%, rgba(0,0,0,1) 100%);
`;

const TitleSection = styled.section`
  color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 6rem;
  & > h1 {
    text-shadow: 2px 2px 2px rgb(0 0 0 / 10%);
    font-weight: 700;
    line-height: 1.3;
    font-size: 4.5rem;
    font-family: "Open Sans", sans-serif;
  }

  & > span {
    text-shadow: 2px 2px 2px rgb(0 0 0 / 10%);
    text-align: center;
    font-size: 1.25rem;
    margin-top: 0;
    margin-bottom: 1rem;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
`;

const ButtonSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;

  & > button {
    background-color: white;
    padding: 0.8rem 3rem;
    letter-spacing: 0.1rem;
    font-weight: 700;
    font-size: 1.2rem;
    border-radius: 4px;
    margin-top: 2rem;
  }
`;
