import React from "react";
import styled from "styled-components";
import {
  AiFillLinkedin,
  AiFillYoutube,
} from "react-icons/ai";

export default function Footer() {
  return (
    <FooterComponent>
      <Nav>
        <LinkNav href="https://bezla.com/services" label="SERVICES" />
        <LinkNav href="https://bezla.com/products" label="PRODUCTS" />
        <LinkNav
          href="https://careers.bezla.com/jobs/Careers"
          label="CAREERS"
        />
        <LinkNav href="https://bezla.com/about" label="ABOUT" />
        <LinkNav href="https://bezla.com/team" label="TEAM" />
        <LinkNav href="https://bezla.com/blog" label="BLOG" />
        <LinkNav href="https://training.bezla.com/login" label="SIGN IN" />
        <LinkNav href="https://bezla.com/contact" label="CONTACT" />
        <LinkNav href="https://rate.bezla.com/" label="RATE.BEZLA.COM" />
        <LinkNav href="https://budget.bezla.com/" label="BUDGET.BEZLA.COM" />
        <LinkNav href="https://training.bezla.com/" label="TRAINING.BEZLA.COM" />
      </Nav>
      <InfoSection>
        <Socials>
          <a href="https://www.linkedin.com/company/bezla"><AiFillLinkedin/></a>
          <a href="https://www.youtube.com/channel/UC5c-NLCogs8r0u8jv49RxJg?view_as=subscriber"><AiFillYoutube/></a>
        </Socials>
        <span>Bezla.com LLC</span>
        <span>1800 JFK Blvd Suite 300 PMB 91649 Philadelphia, PA 19103</span>
        <a href="https://bezla.com/tel:+18889998086">+1-888-999-8086</a>
      </InfoSection>
      <Rights>
        <span>All Rights Reserved. BEZLA BEZLA.COM LLC is a registered trademark. The United States Patent and Trademark Office <strong>Registration Number 6618757</strong>.</span>
        <span>© 2018-2024 Bezla.com LLC</span>
      </Rights>
    </FooterComponent>
  );
}

const FooterComponent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 1.25rem 0;
  margin: 1rem auto;
  font-family: "Open Sans", sans-serif;
`;

const Nav = styled.nav`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
  padding: 0 15rem;

  @media (max-width: 1500px) {
    padding: 0 10rem;
  }

  @media (max-width: 1250px) {
    padding: 0 5rem;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    text-align: center;
  }
`;

const LinkNav = ({ selected, label, href }) => {
  return (
    <LinkComponent
      selected={selected}
      style={{ letterSpacing: ".071em" }}
      href={href}
    >
      {label}
    </LinkComponent>
  );
};

const LinkComponent = styled.a`
  padding: 0.5rem;
  font-size: 1.125rem;
  margin-right: 1rem;
  letter-spacing: 0.071em;
  color: white;
  font-weight: ${(props) => (props.selected ? "700" : "400")};
  text-decoration: none;
  white-space: nowrap;
  &:hover {
    color: white;
    text-decoration: none;
  }

  @media (max-width: 900px) {
    margin-right: 0;
  }
`;

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2.5rem auto;
  color: white;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;

  & > span {
    margin-bottom: 1rem;
  }

  & > span:first-of-type {
    font-weight: 700;
  }

  & > a {
    text-decoration: none;
    color: white;
  }
`;

const Socials = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
  
  & > a {
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.125rem;
    background-color: white;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    margin: 0 0.5rem;
  }
`;

const Rights = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: rgb(169, 169, 169);
  font-size: 1.125rem;
  margin: 0.5rem 15rem;
  line-height: 1.5;
  
  & > span:first-of-type {
    flex-grow: 1;
    word-wrap: break-word;
    margin-right: 21rem;
    text-align: left;
  }

  & > span:last-of-type {
    flex-shrink: 0;
    text-align: right;
  }

  @media (max-width: 1500px) {
    margin: 0.5rem 10rem;
  }

  @media (max-width: 1250px) {
    margin: 0.5rem 5rem;

    & > span:first-of-type {
      margin-right: 10rem;
    }
  }

  @media (max-width: 900px) {
    margin: 0.5rem auto;
    flex-direction: column;
    text-align: center;

    & > span:first-of-type {
      text-align: center;
      margin-right: 0;
    }
  }
`;