import axios from "../../utils/requests";
import { toast } from "react-toastify";

// Define Base Url
const BASE_URL =
  window.location.href.indexOf("localhost") > -1
    ? "http://localhost:3333"
    : "https://8pwaczlquf.execute-api.us-east-1.amazonaws.com";

const addDays = (base, days) => {
  const date = new Date(base).getTime() + 1000 * 60 * 60 * 24 * days;
  return new Date(date).toISOString().split("T")[0];
};

// 1. make dates array from start to end date;
const buildParams = (hotelIds, startDate, endDate) => {
  let currentDate = startDate.split("T")[0];
  let arrDates = [];
  do {
    const checkIn = currentDate;
    const checkOut = addDays(checkIn, 1);
    currentDate = checkOut;
    arrDates.push({
      checkIn,
      checkOut,
    });
  } while (new Date(currentDate).getTime() <= new Date(endDate).getTime());
  const data = hotelIds.map((hotelId) => ({
    hotelId,
    dates: arrDates,
  }));
  return { data };
};

// 2. send request to API
const getHotelRates = async (params) => {
  try {
    const url = `${BASE_URL}/features/hotelRates/rates`;
    const {
      data: { jobId },
    } = await axios.post(url, {...params, provider: "tripAdvisor"});
    const data = await getJobResult(jobId);
    if (data.includes("Error")) {
      await timeout(10000);
      return await getHotelRates(params);
    } else {
      return data;
    }
  } catch (err) {
    console.log(err);
    await timeout(10000);
    return await getHotelRates(params);
  }
};

// 2.1 recursive request for updating status of job
const getJobResult = async (jobId, errors = 0) => {
  try {
    const url = `${BASE_URL}/features/hotelRates/getJobId`;
    const { data } = await axios.post(url, {jobId});
    if (data?.data.data === "loading...") {
      await timeout(5000);
      return await getJobResult(jobId);
    } else {
      return data.data;
    }
  } catch (err) {
    return `Error: ${err}`;
  }
};

// 3. transform the payload into the used format used in front-end
const transformData = (data) => {
  data.forEach((hotelObj) => {
    hotelObj.dates.forEach((datesObj) => {
      const isError = typeof datesObj.rates === "string" ? true : false;
      const minRate =
        !isError && datesObj.rates && datesObj.rates.length
          ? Math.min(...datesObj?.rates?.map((itemRate) => itemRate.rate))
          : 0;
      const bestPriceProviders =
        datesObj.rates && !isError
          ? datesObj.rates
              .filter((item) => String(item.rate) === String(minRate))
              .map((item) => item.provider)
          : [];
      datesObj.date = datesObj.checkIn;
      datesObj.rate = minRate;
      datesObj.code = bestPriceProviders;
      datesObj.error = isError;
      datesObj.error_message = isError ? datesObj.rates : null;
    });
  });

  return data;
};

// 4. return the data
const getRates = async (hotels, checkIn, checkOut) => {
  const params = buildParams(hotels, checkIn, checkOut);
  const data = await getHotelRates(params);
  const parsedData = transformData(data);
  return parsedData;
};

function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default getRates;
